import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({
    baseURL: config.apiUrl
});

// Request configurations
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const token = localStorage.getItem("adminToken");
        if (token) {
            config.headers.common["x-access-token"] = token;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Response configurations
axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                localStorage.removeItem("adminToken");
                window.location.href = "/";
            }
        }
        return Promise.reject(error.response.data);
    }
);

export default axiosInstance;
