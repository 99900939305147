import React, { useState } from "react";
import "./styles.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../config/axios";
import { toast } from 'react-toastify';

const AddUser = () => {

    const [loading, setLoading] = useState(false);

    const userSchema = Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
            .email("Email is not valid")
            .required("Email is required")
    });

    const addNewUser = async (values, resetForm) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/users/add-new-user", values);

            if (response.status) {
                setLoading(false);
                toast.success(response.message);
                resetForm();
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div className="user-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-container">
                            <div className="form-section">
                                <Formik
                                    initialValues={{
                                        first_name: "",
                                        last_name: "",
                                        email: "",
                                    }}
                                    validationSchema={userSchema}
                                    onSubmit={(values, { resetForm }) => {
                                        addNewUser(values, resetForm)
                                    }}
                                >
                                    {() => (
                                        <Form className={"intro__form"}>
                                            <p>Create a New user on One pager</p>
                                            <br />
                                            <div className={"create-user-fields"}>
                                                <label htmlFor="fname">First Name</label>
                                                <Field
                                                    type="text"
                                                    name="first_name"
                                                    className="form-control"
                                                    placeholder="" />

                                                <ErrorMessage
                                                    className={"validation__error"}
                                                    name="first_name"
                                                    component="div"
                                                />
                                            </div>

                                            <div className={"create-user-fields"}>
                                                <label htmlFor="lname">Last Name</label>
                                                <Field
                                                    type="text"
                                                    name="last_name"
                                                    className="form-control"
                                                    placeholder="" />

                                                <ErrorMessage
                                                    className={"validation__error"}
                                                    name="last_name"
                                                    component="div"
                                                />
                                            </div>

                                            <div className={"create-user-fields"}>
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder=""
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    className={"validation__error"}
                                                    name="email"
                                                    component="div"
                                                />
                                            </div>

                                            <div className={"intro__group"}>
                                                <input
                                                    type="submit"
                                                    value="Create User"
                                                    disabled={loading}
                                                    className={"btn btn-primary create-user-btn"}
                                                />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddUser;