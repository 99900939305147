import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { toast } from 'react-toastify';

const SignUp = () => {

    const [loading, setLoading] = useState(false);

    const signupSchema = Yup.object().shape({
        name: Yup.string().required("First name is required"),
        email: Yup.string()
            .email("Email is not valid")
            .required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be atleast 6 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], "Confirm password must match")
            .min(6, "Password must be atleast 6 characters")
            .required("Password confirmation is required")
    });

    const registerAdmin = async (values, resetForm) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/sign-up", values);

            if (response.status) {
                toast.success(response.message);
                resetForm();
            }

        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="account">
            <div className={"wrapper"}>
                <header className={"header"}>
                    <div className={"intro"}>
                        <div className={"intro__logo"}>
                            <Link to="/">
                                <img
                                    src="../img/account/header-logo.png"
                                    alt="eBrandBuilder"
                                />
                            </Link>
                        </div>

                        <div className={"container"}>
                            <div className={"intro__title"}>
                                <h1>
                                    Use the form <br />
                                    to create an <br />
                                    administrator account
                                </h1>
                            </div>

                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    password: "",
                                    confirmPassword: ""
                                }}
                                validationSchema={signupSchema}
                                onSubmit={(values, { resetForm }) => {
                                    registerAdmin(values, resetForm)
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={"intro__form"}>

                                        <div className={"intro__group"}>
                                            <label htmlFor="fname">Name</label>
                                            <Field type="text" name="name" placeholder="" />
                                            <ErrorMessage
                                                className={"validation__error"}
                                                name="name"
                                                component="div"
                                            />
                                        </div>

                                        <div className={"intro__group"}>
                                            <label htmlFor="fname">email</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder=""
                                            />
                                            <ErrorMessage
                                                className={"validation__error"}
                                                name="email"
                                                component="div"
                                            />
                                        </div>

                                        <div className={"intro__group"}>
                                            <label htmlFor="fname">password</label>
                                            <Field
                                                type="password"
                                                name="password"
                                                placeholder=""
                                            />
                                            <ErrorMessage
                                                className={"validation__error"}
                                                name="password"
                                                component="div"
                                            />
                                        </div>

                                        <div className={"intro__group"}>
                                            <label htmlFor="fname">confirm password</label>
                                            <Field
                                                type="password"
                                                name="confirmPassword"
                                                placeholder=""
                                            />
                                            <ErrorMessage
                                                className={"validation__error"}
                                                name="confirmPassword"
                                                component="div"
                                            />
                                        </div>

                                        <div className={"intro__group"}>
                                            <input
                                                type="submit"
                                                value="Create account"
                                                disabled={loading}
                                                className={"signup__btn"}
                                            />
                                            <Link to="/">Click here to login</Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default SignUp;