import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const { token } = useParams();

    const resetPasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(6, "Password must be atleast 6 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword")], "Confirm password must match")
            .min(6, "Password must be atleast 6 characters")
            .required("Password confirmation is required")
    });

    const [verificationId, setVerificationId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token) {
            verifyToken(token);
        }
    }, [token]);

    const verifyToken = async (token) => {
        try {
            const response = await axiosInstance.post("/forgot-password/verify", { token });

            if (response.status && response.id) {
                setVerificationId(response.id);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const resetPassword = async (values, resetForm) => {
        try {
            setLoading(true);
            const response = await axiosInstance.put("/forgot-password/reset", {
                id: verificationId,
                password: values.newPassword
            });

            if (response.status) {
                setLoading(false);
                toast.success(response.message);
                resetForm();
            }

        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <div id="access">
            <main className={"wrapper"}>
                <div className={"access"}>
                    <div className={"container"}>
                        <div className={"access__logo"}>
                            <Link to="/">
                                <img src="../img/access/logo.png" alt="eBrandBuilders" />
                            </Link>
                        </div>

                        <Formik
                            initialValues={{ newPassword: "", confirmPassword: "" }}
                            validationSchema={resetPasswordSchema}
                            onSubmit={(values, { resetForm }) => {
                                resetPassword(values, resetForm);
                            }}>
                            {() => (
                                <Form className={"access__form"}>
                                    <div className={"access__group"}>
                                        <Field
                                            type="password"
                                            name="newPassword"
                                            placeholder="New Password"
                                        />
                                        <ErrorMessage
                                            className="validation__error"
                                            name="newPassword"
                                            component="div"
                                        />
                                    </div>

                                    <div className={"access__group"}>
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                        />
                                        <ErrorMessage
                                            className="validation__error"
                                            name="confirmPassword"
                                            component="div"
                                        />
                                    </div>

                                    <div className={"access__group"}>
                                        <input
                                            type="submit"
                                            disabled={loading}
                                            value="Reset My Password"
                                        />
                                    </div>

                                    <div className={"access__group"}>
                                        <Link to="/">click here to login</Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ResetPassword;