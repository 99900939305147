import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);

    const forgotPasswordInitSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email is not valid")
            .required("Email is required")
    });

    const initiatePasswordReset = async (values, resetForm) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/forgot-password/initiate", values);

            if (response.status) {
                toast.success(response.message);
                resetForm();
            }

        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="access">
            <main className={"wrapper"}>
                <div className={"access"}>
                    <div className={"container"}>
                        <div className={"access__logo"}>
                            <Link to="/">
                                <img src="../img/access/logo.png" alt="eBrandBuilders" />
                            </Link>
                        </div>
                        <Formik
                            initialValues={{ email: "" }}
                            validationSchema={forgotPasswordInitSchema}
                            onSubmit={(values, { resetForm }) => {
                                initiatePasswordReset(values, resetForm);
                            }}>
                            {() => (
                                <Form className={"access__form"}>
                                    <div className={"access__group"}>
                                        <Field type="email" name="email" placeholder="Email Address" />
                                        <ErrorMessage
                                            name="email"
                                            className="validation__error"
                                            component="div"
                                        />
                                    </div>
                                    <div className={"access__group"}>
                                        <Link to="/">click here to login</Link>
                                    </div>
                                    <div className={"access__group"}>
                                        <input type="submit" disabled={loading}
                                            value="Reset My Password" />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ForgotPassword;