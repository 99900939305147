import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./pages/login";
import SignUp from "./pages/sign-up";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from "./layout/protectedRoutes";
import NotFound from "./pages/404";

const App = () => {
  return (
    <div className="wrapper">
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password/reset/:token" component={ResetPassword} />

        <Route exact path="/dashboard" component={ProtectedRoute} />
        <Route path="*" component={NotFound} />
      </Switch>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false} />
    </div>
  );
}

export default withRouter(App);
