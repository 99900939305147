import React, { useEffect } from "react";
import AddUser from "../pages/users";
import { Route, Switch, useHistory } from "react-router-dom";
import "./styles.css";
import NotFound from "../pages/404";

const ProtectedRoute = () => {
    const history = useHistory();

    const logout = () => {
        localStorage.removeItem("adminToken");
        window.location = "/";
    };

    useEffect(() => {
        const token = localStorage.getItem("adminToken");

        if (!token) {
            history.push("/");
        }
    }, [history]);

    return (
        <div className="layout-container">
            <div className="children-container">
                <div className="header-section">
                    <div className='logo-section'>
                        <div>
                            <img
                                src="../img/download/footer-image.png"
                                className="dash-logo"
                                alt="eBrandBuilders" />
                        </div>
                        <div className="logo-text"> One Pager Admin</div>
                    </div>
                    <div className="header-text-section">
                        <ul>
                            <li onClick={logout}>Logout</li>
                        </ul>
                    </div>
                </div>
                <Switch>
                    <Route path="/" component={AddUser} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
        </div >
    );
};

export default ProtectedRoute;