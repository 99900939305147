import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { toast } from 'react-toastify';

const Login = () => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email is not valid")
            .required("Email is required"),
        password: Yup.string().required("Password is required")
    });

    useEffect(() => {
        const token = localStorage.getItem("adminToken");

        if (token) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = async (values) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/login", values);
            setLoading(false);

            if (response.status && response.token) {
                localStorage.setItem("adminToken", response.token);
                history.push("/dashboard");
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div id="access">
            <main className={"wrapper"}>
                <div className={"access"}>
                    <div className={"container"}>
                        <div className={"access__logo"}>
                            <Link to="/">
                                <img src="../img/access/logo.png" alt="eBrandBuilders" />
                            </Link>
                        </div>

                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={loginSchema}
                            onSubmit={(values) => {
                                login(values)
                            }}>
                            {() => (
                                <Form className={"access__form"}>
                                    <div className={"access__group"}>
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                        />
                                        <ErrorMessage
                                            className="validation__error"
                                            name="email"
                                            component="div"
                                        />
                                    </div>

                                    <div className={"access__group"}>
                                        {!showPassword && <div className={"access__group-wrapper"}>
                                            <Field
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                            />
                                            <label
                                                htmlFor="password"
                                                id="show-pas"
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                            />
                                        </div>}

                                        {showPassword && <div className={"access__group-wrapper"}>
                                            <Field
                                                type="text"
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                            />
                                            <label
                                                htmlFor="password"
                                                id="show-pas"
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                            />
                                        </div>}

                                        <ErrorMessage
                                            className="validation__error"
                                            name="password"
                                            component="div"
                                        />
                                    </div>

                                    <div className={"access__group"}>
                                        <Link to="/forgot-password">forgot your password?</Link>
                                    </div>

                                    <div className={"access__group"}>
                                        <input
                                            type="submit"
                                            value="Log Me In"
                                            disabled={loading}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Login;