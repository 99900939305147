// Local version
// const config = {
//     apiUrl: "http://localhost:3000/api/v1/admin/",
// };

// Live version
const config = {
    apiUrl: "https://api.onepager.in/api/v1/admin/",
  };

export default config;